import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  HomeR,
  ProfitStep,
  ExampleRequest,
  CalculatorFinancingContent,
} from "../components/_landing"

const RemodelaPage = () => (
  <Layout>
    <SEO title="Remodela" />

    <HomeR />
    <div id="beneficios" />
    <ProfitStep />
    <CalculatorFinancingContent />
    <ExampleRequest />
    <div id="contacto" />
  </Layout>
)

export default RemodelaPage
